






import assetx from '@/store/modules/assetx';
import globalx from '@/store/modules/globalx';
import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class AccountAssetLoaded extends Vue {
  public get pageReady() {
    if (!globalx.maintenanceInfo) {
      return 'maintenanceCheck';
    }
    if (userx.loginStatus === 'loading') {
      return 'auth';
    }
    if (userx.loginStatus === 'logged in') {
      if (!globalx.userDocsReady) {
        return 'loadingUserDocs';
      }
    }
    if (assetx.assetLoadState === 'completed') {
      return 'done';
    }
    return Math.round(assetx.assetLoadProgress);
  }
  @Watch('pageReady', { immediate: true })
  public pageReadyChanged(newVal: string) {
    switch (newVal) {
      case 'auth':
        this.$root.$emit('loading', 'Authenticating...');
        break;
      case 'loadingUserDocs':
        this.$root.$emit('loading', 'Loading  User  Data...');
        break;
      case 'maintenanceCheck':
        this.$root.$emit('loading', 'Checking  For  Update...');
        break;
      case 'done':
        this.$root.$emit('loading', '');
        break;
      default:
        this.$root.$emit('loading', `Loading Assets...${newVal}%`);
        break;
    }
  }
}
